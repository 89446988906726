export const ROUTE_URL = {
  signUp: '/sign-up',
  signUpChannels: '/sign-up/channels',
  dialog: '/chats/my-tasks',
  inbox: '/chats/new',
  all: '/chats/all',
  chats: '/chats',
  analytics: '/analytics',
  analyticsOperators: '/analytics/operators',
  analyticsChannels: '/analytics/channels',
  analyticsOperatorsTabPath: 'operators',
  analyticsChannelsTabPath: 'channels',
  login: '/login',
  settings: '/settings',
  settingsAdminSettings: '/settings/admin-settings',
  settingsTeamMembers: '/settings/team/members',
  settingsTeamDepartments: '/settings/team/departments',
  settingsChannels: '/settings/channels',
  settingsChannelsGeneral: 'general',
  settingsChannelsAssign: 'assign',
  settingsChannelsGreetings: 'greetings',
  settingsChannelsWidgetSettings: 'widget-settings',
  settingsChannelsChatAssignment: 'chat-assignment',
  settingsChannelsWidgetAppearance: 'appearance',
  settingsWidgetChannels: 'widget-channels',
  settingsChannelsInstallation: 'installation',
  settingsCategories: '/settings/categories',
  settingsTemplates: '/settings/templates',
  settingsTemplateMessages: '/settings/template-messages',
  settingsIntegrations: '/settings/integrations',
  settingsPlans: '/settings/plans',
  settingsBilling: '/settings/billing',
  settingsBillingDetails: '/settings/billing/details',
  settingsBillingManageSeatsPath: 'manage-seats',
  settingsChatRescue: '/settings/chat-rescue',
  settingsPersonalAccount: '/settings/personal/account',
  settingsAppSettings: '/settings/app/settings',
  successful: '/successful',
  failed: '/failed',
  payment: '/payment',
  shopifyPayment: '/shopify-payment',
  settingsIntegrationsSetup: '/settings/integrations/setup',
  openAiChatGptPath: 'open-ai-chat-gpt',
  newBotPath: 'new',
  openAiChatGptBotGeneralTabPath: 'general',
  openAiChatGptBotChatsLogicTabPath: 'chats-logic',
  openAiChatGptBotPromptTabPath: 'prompt',
  creatioIntegrationGeneralTabPath: 'general',
  creatioIntegrationLeadTabPath: 'lead',
  creatioIntegrationContactTabPath: 'contact',
  pageNotFound: '/404',
  forgotPassword: '/forgot-password',
  subscriptionPlans: '/subscription-plans',
  shopifyCallbackParameter: 'charge_id',
};
