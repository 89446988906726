import { Component, Inject } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { MessengerProviderName, MessengerType } from '@app/graphql/graphql';
import { toLowerCaseWithCapitalFirstLetter } from '@app/util/text.util';
import { Tag } from '@app/common/model/tag';
import { ModalWindow } from '@app/sign-up-channels-page/channels/create-channel-buttons/modal-window';
import { ChannelType, Type } from '@app/model/channel-type';
import { AddChannelModalService } from '@app/settings/channels-page/add-channel-modal/service/add-channel-modal-service';

@Component({
  selector: 'app-create-telegram-channel',
  templateUrl: './create-telegram-channel.component.html',
  styleUrls: ['./create-telegram-channel.component.scss'],
})
export class CreateTelegramChannelComponent implements ModalWindow {
  private modalRef: NzModalRef;

  readonly channelTypes: ChannelType[] = [
    {
      type: Type.BOT,
      title: 'Telegram Bot',
      description: this.translationService.t('bot_channel.description'),
      isPaid: false,
    },
    {
      type: Type.DIRECT,
      title: this.translationService.t('e_chat_channel', { messenger: toLowerCaseWithCapitalFirstLetter(MessengerType.Telegram) }),
      description: this.translationService.t('e_chat_channel.description', { messenger: toLowerCaseWithCapitalFirstLetter(MessengerType.Telegram) }),
      isPaid: true,
      providerName: MessengerProviderName.EChat,
    },
  ];

  currentChannelType: ChannelType;

  showBackButton = false;

  constructor(
      private addChannelModalService: AddChannelModalService,
      private modalService: NzModalService,
      @Inject(I18NEXT_SERVICE)
      private translationService: ITranslationService,
  ) {
  }

  showModal(showBackButton?: boolean): void {
    this.modalService.closeAll();

    const modalRef = this.modalService.create(
      {
        nzWidth: '572px',
        nzMaskClosable: false,
        nzContent: CreateTelegramChannelComponent,
        nzFooter: null,
      },
    );

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.showBackButton = showBackButton;
  }

  selectChannelType(channelType: ChannelType): void {
    this.modalRef.updateConfig(
      {
        nzTitle: null,
        nzFooter: null,
      },
    );
    this.currentChannelType = channelType;
  }

  deselectChannelType(): void {
    this.modalRef.updateConfig(
      {
        nzTitle: null,
        nzFooter: null,
      },
    );
    this.currentChannelType = null;
  }

  back(): void {
    this.modalService.closeAll();
    this.addChannelModalService.showModal();
  }

  protected readonly MessengerType = MessengerType;

  protected readonly Tag = Tag;
}
